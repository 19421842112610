import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  brandLogo,
  logoutImg,
  home,
  purchase,
  setting,
  support,
  products,
  chat,
  service,
  orders,
  pos,
  trackings,
  wallet,
  users,
  campaign,
  analytics,
  catalog,
  analyticsicon,
} from "../../../utilities/images";
import {
  getUserByIdApiAsync,
  logout,
  selectLoginAuth,
} from "../../containers/auth/authSlice";
import { Wallet } from "../../containers";
import { toast } from "react-toastify";
import {
  dashboardEmpty,
  getMerchantStepStatusAsync,
  getWalletBallanceApiAsync,
  selectMerchantStatus,
} from "../../containers/dashboard/dashboardSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { productEmpty } from "../../containers/myProducts/productSlice";
import { calendar } from "../../../utilities/images";

const Sidebar = (props) => {
  const location = useLocation();
  const pathName = location.pathname;
  // const [isWallet, setIsWallet] = useState('');
  const auth = useSelector(selectLoginAuth);
  const sellerType = auth?.payload?.user?.user_profiles?.seller_type;

  const ADMIN = () => {
    const admin = auth?.payload?.user?.user_roles?.filter(
      (item) => item?.role?.slug == "admin"
    );
    return admin;
  };

  const merchantStatus = useSelector(selectMerchantStatus);
  const [activeData, setActiveData] = useState("Dashboard");
  const history = useHistory();
  const dispatch = useDispatch();

  const closeActions = () => {
    document.body.setAttribute("style", "overflow:auto");
  };
  const closeSide = () => {
    props.closeNav();
    document.body.setAttribute("style", "overflow:auto");
  };

  const userLogout = async () => {
    await dispatch(logout());
    await dispatch(productEmpty());
    await dispatch(dashboardEmpty());
    window.localStorage.clear();
    toast.success("Successfully Logout");
    await history.push("/");
  };

  // const getUserDataByIdHandle = () => {
  //     let data = {
  //       postData: {
  //         id: auth?.payload?.id ? auth?.payload?.id : auth?.payload?.user?.id
  //       },
  //       tokenData: auth?.payload?.token
  //     }
  //     dispatch(getUserByIdApiAsync(data))
  //       .then(unwrapResult)
  //       .then((obj) => {
  //         setIsWallet(obj?.payload?.is_wallet)
  //       })
  //       .catch((obj) => {
  //       })
  //   }
  const getMerchantStepStatus = () => {
    dispatch(getMerchantStepStatusAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then((obj) => {})
      .catch((obj) => {});
  };
  useEffect(() => {
    // getUserDataByIdHandle()
    getMerchantStepStatus();
  }, []);

  useEffect(() => {
    setActiveData(pathName);
  }, []);


  return (
    <aside
      className={`vertical-tab ${props?.nav?.open_nav ? "slideOpen" : ""}`}
      id="myNav"
    >
      <Link to="/wallet" className="brandLogo">
        <img src={brandLogo} className="img-fluid" alt="" />
      </Link>
      <ul className="sidebarMenus navbar_overlay_content_">
        <div className="sidebarStaticMenus">
          <li
            className={
              activeData == "/wallet"
                ? "sidebarItems dropdown active"
                : "sidebarItems dropdown"
            }
          >
            <Link
              to="/wallet"
              onClick={() =>
                activeData === "/wallet"
                  ? setActiveData("")
                  : setActiveData("/wallet")
              }
              className={
                activeData === "/wallet"
                  ? "sidebarLinks dropdown-toggle show"
                  : "sidebarLinks dropdown-toggle"
              }
              id="sidebarDropdown"
            >
              <img src={home} className="img-fluid showImg" alt="" />
              <span className="menusTxt">Home</span>
              {/* <i className="fa-solid fa-caret-down caretIcon"
                            ></i> */}
            </Link>
            {/* Start SidebarInnerMenus */}
            {/* <ul className={activeData === "/wallet" ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                            aria-labelledby="sidebarDropdown">
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/wallet"
                                    className={pathName == '/wallet' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Wallet
                                </Link>
                            </li>
                        </ul> */}
            {/* End SidebarInnerMenus */}
          </li>
          {ADMIN()?.length > 0 && (
            <>
              <li
                className={
                  activeData == "/subscriber"
                    ? "sidebarItems dropdown active"
                    : "sidebarItems dropdown"
                }
              >
                <Link
                  to="/subscriber"
                  onClick={() =>
                    activeData === "/subscriber"
                      ? setActiveData("")
                      : setActiveData("/subscriber")
                  }
                  className={
                    activeData === "/subscriber"
                      ? "sidebarLinks dropdown-toggle show"
                      : "sidebarLinks dropdown-toggle"
                  }
                  id="sidebarDropdown"
                >
                  <img src={users} className="img-fluid showImg" alt="" />
                  <span className="menusTxt">Subscriber</span>
                </Link>
              </li>
              <li
                className={
                  activeData == "/googleAnalytics"
                    ? "sidebarItems dropdown active"
                    : "sidebarItems dropdown"
                }
              >
                <Link
                  to="/googleAnalytics"
                  onClick={() =>
                    activeData === "/googleAnalytics"
                      ? setActiveData("")
                      : setActiveData("/googleAnalytics")
                  }
                  className={
                    activeData === "/googleAnalytics"
                      ? "sidebarLinks dropdown-toggle show"
                      : "sidebarLinks dropdown-toggle"
                  }
                  id="sidebarDropdown"
                >
                  <img src={users} className="img-fluid showImg" alt="" />
                  <span className="menusTxt">Google Analytics</span>
                </Link>
              </li>
            </>
          )}
          <li
            className={
              activeData == "/directMessage" ||
              activeData == "/inquaries" ||
              activeData == "/rfqMessage"
                ? "sidebarItems dropdown active"
                : "sidebarItems dropdown"
            }
          >
            <Link
              to="#"
              className={
                activeData === "/directMessage" ||
                activeData == "/inquaries" ||
                activeData == "/rfqMessage"
                  ? "sidebarLinks dropdown-toggle show"
                  : "sidebarLinks dropdown-toggle"
              }
              onClick={() =>
                activeData === "/directMessage" ||
                activeData == "/inquaries" ||
                activeData == "/rfqMessage"
                  ? setActiveData("")
                  : setActiveData("/directMessage")
              }
              aria-expanded="false"
              id="sidebarDropdown"
            >
              <img src={chat} className="img-fluid showImg" alt="" />
              <span className="menusTxt">Messages</span>
              <i class="fa-solid fa-angle-down caretIcon"></i>
            </Link>
            {/* Start SidebarInnerMenus */}
            <ul
              className={
                activeData === "/directMessage" ||
                activeData == "/inquaries" ||
                activeData == "/rfqMessage"
                  ? "dropdown-menu sidebarDropMenus show"
                  : "dropdown-menu sidebarDropMenus"
              }
              aria-labelledby="sidebarDropdown"
            >
              <li className="sidebarInnerItems">
                <Link
                  to="/directMessage"
                  className={
                    pathName === "/directMessage"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Direct Message
                </Link>
              </li>
              {/* <li className='sidebarInnerItems'>
                                <Link
                                    to="/inquaries"
                                    className={pathName === '/inquaries' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Inquaries
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/rfqMessage"
                                    className={pathName === '/rfqMessage' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    RFQ
                                </Link>
                            </li> */}
            </ul>
            {/* End SidebarInnerMenus */}
          </li>
          <li
            className={
              activeData == "/myProducts" ||
              activeData == "/productList" ||
              activeData == "/categoryListview" ||
              activeData == "/product-subcategory" ||
              activeData == "/product-brands"
                ? "sidebarItems dropdown active"
                : "sidebarItems dropdown"
            }
          >
            <Link
              to="#"
              className={
                activeData == "/myProducts" ||
                activeData == "/productList" ||
                activeData == "/categoryListview" ||
                activeData == "/product-subcategory" ||
                activeData == "/product-brands"
                  ? "sidebarLinks dropdown-toggle show"
                  : "sidebarLinks dropdown-toggle"
              }
              onClick={() =>
                activeData === "/myProducts" ||
                activeData == "/productList" ||
                activeData == "/categoryListview" ||
                activeData == "/product-subcategory" ||
                activeData == "/product-brands" ||
                activeData == "/myProducts"
                  ? setActiveData("")
                  : setActiveData("/myProducts")
              }
              aria-expanded="false"
              id="sidebarDropdown"
            >
              <img src={products} className="img-fluid showImg" alt="" />
              {/* <img src={shippingOrderDark} className="img-fluid hoverImg" alt="" /> */}
              <span className="menusTxt">Products</span>
              <i class="fa-solid fa-angle-down caretIcon"></i>
            </Link>
            {/* Start SidebarInnerMenus */}
            <ul
              className={
                activeData === "/myProducts" ||
                activeData == "/productList" ||
                activeData == "/categoryListview" ||
                activeData == "/product-subcategory" ||
                activeData == "/product-brands"
                  ? "dropdown-menu sidebarDropMenus show"
                  : "dropdown-menu sidebarDropMenus"
              }
              aria-labelledby="sidebarDropdown"
            >
              <li className="sidebarInnerItems">
                <Link
                  to="/myProducts"
                  className={
                    pathName == "/myProducts"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Overview
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/productList"
                  className={
                    pathName == "/productList"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Products
                </Link>
              </li>
              {/* <li className='sidebarInnerItems'>
                                <Link
                                    to="/categoryListview/category/product"
                                    className={pathName == '/categoryListview/category' || (pathName.split('/')[2] === "category" && pathName.split('/')[3] === "product") ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Categories
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/categoryListview/sub_category/product"
                                    className={pathName == '/categoryListview/sub_category' || (pathName.split('/')[2] === "sub_category" && pathName.split('/')[3] === "product") ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Sub-categories
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/categoryListview/brand/product"
                                    className={pathName == '/categoryListview/brand' || (pathName.split('/')[2] === "brand" && pathName.split('/')[3] === "product") ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Brands
                                </Link>
                            </li>*/}
              <li className="sidebarInnerItems">
                <Link
                  to="/productOffer"
                  className={
                    pathName == "/productOffer"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Offers
                </Link>
              </li>
            </ul>
            {/* End SidebarInnerMenus */}
          </li>
          {sellerType == "manufacturer" || sellerType == "whole_seller" ? (
            ""
          ) : (
            <li
              className={
                activeData == "/myService" ||
                activeData == "/service-list" ||
                activeData == "/service-category" ||
                activeData == "/service-subcategory"
                  ? "sidebarItems dropdown active"
                  : "sidebarItems dropdown"
              }
            >
              <Link
                to="#"
                className={
                  activeData === "/myService"
                    ? "sidebarLinks dropdown-toggle show"
                    : "sidebarLinks dropdown-toggle"
                }
                onClick={() =>
                  activeData === "/myService" ||
                  activeData == "/service-list" ||
                  activeData == "/service-category" ||
                  activeData == "/service-subcategory"
                    ? setActiveData("")
                    : setActiveData("/myService")
                }
                id="sidebarDropdown"
              >
                <img src={service} className="img-fluid showImg" alt="" />
                <span className="menusTxt">Services</span>
                <i class="fa-solid fa-angle-down caretIcon"></i>
              </Link>
              {/* Start SidebarInnerMenus */}
              <ul
                className={
                  activeData === "/myService" ||
                  activeData == "/service-list" ||
                  activeData == "/service-category" ||
                  activeData == "/service-subcategory"
                    ? "dropdown-menu sidebarDropMenus show"
                    : "dropdown-menu sidebarDropMenus"
                }
                aria-labelledby="sidebarDropdown"
              >
                <li className="sidebarInnerItems">
                  <Link
                    to="/myService"
                    className={
                      pathName == "/myService"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                  >
                    <svg
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="3"
                        cy="3"
                        r="3"
                        fill="#DFDFDF"
                        className="circledot"
                      />
                    </svg>
                    Overview
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/service-list"
                    className={
                      pathName == "/service-list"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                  >
                    <svg
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="3"
                        cy="3"
                        r="3"
                        fill="#DFDFDF"
                        className="circledot"
                      />
                    </svg>
                    Services
                  </Link>
                </li>
                {/* <li className='sidebarInnerItems'>
                                <Link
                                    to="/categoryListview/category/service"
                                    className={pathName == '/service-category' || (pathName.split('/')[2] === "category" && pathName.split('/')[3] === "service") ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Categories
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/categoryListview/sub_category/service"
                                    className={pathName == '/service-subcategory' || (pathName.split('/')[2] === "sub_category" && pathName.split('/')[3] === "service") ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Sub-categories
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/categoryListview/brand/service"
                                    className={pathName == '/categoryListview/brand' || (pathName.split('/')[2] === "brand" && pathName.split('/')[3] === "service") ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Brands
                                </Link>
                            </li>*/}
                <li className="sidebarInnerItems">
                  <Link
                    to="/serviceOffer"
                    className={
                      pathName == "/serviceOffer"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                  >
                    <svg
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="3"
                        cy="3"
                        r="3"
                        fill="#DFDFDF"
                        className="circledot"
                      />
                    </svg>
                    Offers
                  </Link>
                </li>
              </ul>
              {/* End SidebarInnerMenus */}
            </li>
          )}
          <li
            className={
              activeData === "/myOrders" ||
              activeData === "/newOrder" ||
              activeData === "/processingOrders" ||
              activeData === "/completeOrders" ||
              activeData === "/cancelOrders" ||
              activeData === "/refundOrders"
                ? "sidebarItems dropdown active"
                : "sidebarItems dropdown"
            }
          >
            <Link
              to="#"
              className={
                activeData === "/myOrders" ||
                activeData === "/newOrder" ||
                activeData === "/processingOrders" ||
                activeData === "/completeOrders" ||
                activeData === "/cancelOrders" ||
                activeData === "/refundOrders"
                  ? "sidebarLinks dropdown-toggle show"
                  : "sidebarLinks dropdown-toggle"
              }
              onClick={() =>
                activeData === "/myOrders" ||
                activeData === "/newOrder" ||
                activeData === "/processingOrders" ||
                activeData === "/completeOrders" ||
                activeData === "/cancelOrders" ||
                activeData === "/refundOrders"
                  ? setActiveData("")
                  : setActiveData("/myOrders")
              }
              id="sidebarDropdown"
            >
              <img src={orders} className="img-fluid showImg" alt="" />
              {/* <img src={fiatTockenActive} className="img-fluid hoverImg" alt="" /> */}
              <span className="menusTxt">Orders</span>
              <i class="fa-solid fa-angle-down caretIcon"></i>
            </Link>
            {/* Start SidebarInnerMenus */}
            <ul
              className={
                activeData === "/myOrders" ||
                activeData === "/newOrder" ||
                activeData === "/processingOrders" ||
                activeData === "/completeOrders" ||
                activeData === "/cancelOrders" ||
                activeData === "/refundOrders"
                  ? "dropdown-menu sidebarDropMenus show"
                  : "dropdown-menu sidebarDropMenus"
              }
              aria-labelledby="sidebarDropdown"
            >
              <li className="sidebarInnerItems">
                <Link
                  to="/myOrders"
                  className={
                    pathName == "/myOrders"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Overview
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/allOrder"
                  className={
                    pathName == "/allOrder" ||
                    pathName.split("/")[1] === "orderReview" ||
                    pathName.split("/")[1] === "orderTrack"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Orders
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/productRefund"
                  className={
                    pathName == "/productRefund"
                      ? // ||
                        // pathName.split("/")[1] === "orderReview" ||
                        // pathName.split("/")[1] === "orderTrack"
                        "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Product Refunds
                </Link>
              </li>
              {/* <li className='sidebarInnerItems'>
                                <Link to="/processingOrders"
                                    className={pathName == '/processingOrders' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Processing Orders
                                </Link>
                            </li> */}
              {/* <li className='sidebarInnerItems'>
                                <Link
                                    to="/completeOrders"
                                    className={pathName == '/completeOrders' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Completed Orders
                                </Link>
                            </li> */}
              {/* <li className='sidebarInnerItems'>
                                <Link
                                    to="/cancelOrders"
                                    className={pathName == '/cancelOrders' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Cancelled Orders
                                </Link>
                            </li> */}
              {/* <li className='sidebarInnerItems'>
                                <Link
                                    to="/refundOrders"
                                    className={pathName == '/refundOrders' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Returned Orders
                                </Link>
                            </li> */}
            </ul>
            {/* End SidebarInnerMenus */}
          </li>
          {sellerType == "manufacturer" || sellerType == "whole_seller" ? (
            ""
          ) : (
            <li
              className={
                activeData == "/bookings "
                  ? "sidebarItems dropdown active"
                  : "sidebarItems dropdown"
              }
            >
              <Link
                to="#"
                className={
                  activeData === "/bookings" || activeData === "/bookings"
                    ? "sidebarLinks dropdown-toggle show"
                    : "sidebarLinks dropdown-toggle"
                }
                onClick={() =>
                  activeData === "/bookings" || activeData === "/bookings"
                    ? setActiveData("")
                    : setActiveData("/bookings")
                }
                id="sidebarDropdown"
              >
                <img src={calendar} className="img-fluid" alt="" />
                <span className="menusTxt">Bookings </span>
                <i class="fa-solid fa-angle-down caretIcon"></i>
              </Link>
              <ul
                className={
                  activeData === "/bookings" || activeData === "/stats"
                    ? "dropdown-menu sidebarDropMenus show"
                    : "dropdown-menu sidebarDropMenus"
                }
                aria-labelledby="sidebarDropdown"
              >
                <li className="sidebarInnerItems">
                  <Link
                    to="/bookings"
                    className={
                      pathName == "/bookings"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                  >
                    <svg
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="3"
                        cy="3"
                        r="3"
                        fill="#DFDFDF"
                        className="circledot"
                      />
                    </svg>
                    Overview
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/upcoming-bookings"
                    className={
                      pathName == "/upcoming-bookings"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                  >
                    <svg
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="3"
                        cy="3"
                        r="3"
                        fill="#DFDFDF"
                        className="circledot"
                      />
                    </svg>
                    Calendar
                  </Link>
                </li>
                <li className="sidebarInnerItems">
                  <Link
                    to="/completed-bookings"
                    className={
                      pathName == "/completed-bookings"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                  >
                    <svg
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="3"
                        cy="3"
                        r="3"
                        fill="#DFDFDF"
                        className="circledot"
                      />
                    </svg>
                    Appointments
                  </Link>
                </li>
              </ul>
            </li>
          )}
          {/* <li className={activeData == '/myAppointments' || activeData == '/todayAppointments' ? "sidebarItems dropdown active" : "sidebarItems dropdown"}>
                        <Link to="#"
                            className={activeData == '/myAppointments' || activeData == '/todayAppointments' ? "sidebarLinks dropdown-toggle show" : "sidebarLinks dropdown-toggle"}
                            onClick={() => activeData == '/myAppointments' || activeData == '/todayAppointments' ? setActiveData("") : setActiveData("/myAppointments")}
                            id="sidebarDropdown">
                            <img src={calendar_light} className="img-fluid showImg" alt="" />
                            <span className='menusTxt'>Appointments</span>
                            <i class="fa-solid fa-angle-down caretIcon"></i>
                        </Link>
                        <ul className={activeData == '/myAppointments' || activeData == '/todayAppointments' ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                            aria-labelledby="sidebarDropdown">
                            <li className='sidebarInnerItems'>
                                <Link to="/myAppointments"
                                    className={pathName == '/myAppointments' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Overview
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/todayAppointments"
                                    className={pathName == '/todayAppointments' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Today’s Appointments
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/upcomingDetails"
                                    className={pathName == '/upcomingDetails' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Upcoming Appointments
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/appointments/fulfilled/2"
                                    className={pathName.split('/')[2] === 'fulfilled' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Fulfilled Appointments
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/appointments/cancelled/3"
                                    className={pathName.split('/')[2] === 'cancelled' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Canceled Appointments
                                </Link>
                            </li>
                        </ul>
                    </li> */}

          <li
            className={
              activeData == "/myPos" ||
              activeData == "/posSales" ||
              activeData == "/createUser"
                ? "sidebarItems dropdown active"
                : "sidebarItems dropdown"
            }
          >
            <Link
              to="#"
              className={
                activeData == "/myPos" ||
                activeData == "/posSales" ||
                activeData == "/createUser"
                  ? "sidebarLinks dropdown-toggle show"
                  : "sidebarLinks dropdown-toggle"
              }
              onClick={() =>
                activeData == "/myPos" ||
                activeData == "/posSales" ||
                activeData == "/createUser"
                  ? setActiveData("")
                  : setActiveData("/myPos")
              }
              id="sidebarDropdown"
            >
              <img src={pos} className="img-fluid" alt="" />
              <span className="menusTxt">My POS</span>
              <i class="fa-solid fa-angle-down caretIcon"></i>
            </Link>
            <ul
              className={
                activeData == "/myPos" ||
                activeData == "/posSales" ||
                activeData == "/createUser"
                  ? "dropdown-menu sidebarDropMenus show"
                  : "dropdown-menu sidebarDropMenus"
              }
              aria-labelledby="sidebarDropdown"
            >
              <li className="sidebarInnerItems">
                <Link
                  to="/myPos"
                  className={
                    pathName == "/myPos"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Overview
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/posSales"
                  className={
                    pathName == "/posSales"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  POS Sales
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/createUser"
                  className={
                    pathName == "/createUser"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Create User
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/createRole"
                  className={
                    pathName == "/createRole"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Create Role
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/posUser"
                  className={
                    pathName == "/posUser"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Pos User
                </Link>
              </li>
              {/* <li className='sidebarInnerItems'>
                                <Link to="/staffPayment"
                                    className={pathName == '/staffPayment' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Staff Payment
                                </Link>
                            </li> */}
            </ul>
          </li>

          {/* <li className={activeData == '/myPurchases' || activeData == '/newPurchase' || activeData == '/processingPurchase' || activeData == '/receivedPurchase' || activeData == '/cancelPurchase' || activeData == '/purchaseRefund' ? "sidebarItems dropdown active" : "sidebarItems dropdown"}>
                        <Link to="#"
                            className={activeData == '/myPurchases' || activeData == '/newPurchase' || activeData == '/processingPurchase' || activeData == '/receivedPurchase' || activeData == '/cancelPurchase' || activeData == '/purchaseRefund' ? "sidebarLinks dropdown-toggle show" : "sidebarLinks dropdown-toggle"}
                            onClick={() => activeData == '/myPurchases' || activeData == '/newPurchase' || activeData == '/processingPurchase' || activeData == '/receivedPurchase' || activeData == '/cancelPurchase' || activeData == '/purchaseRefund' ? setActiveData("") : setActiveData("/myPurchases")}
                            id="sidebarDropdown">
                            <img src={purchase} className="img-fluid" alt="" />
                            <span className='menusTxt'>Store Purchases</span>
                            <i class="fa-solid fa-angle-down caretIcon"></i>
                        </Link>
                        <ul className={activeData == '/myPurchases' || activeData == '/newPurchase' || activeData == '/processingPurchase' || activeData == '/receivedPurchase' || activeData == '/cancelPurchase' || activeData == '/purchaseRefund' ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                            aria-labelledby="sidebarDropdown">
                            <li className='sidebarInnerItems'>
                                <Link to="/myPurchases"
                                    className={pathName == '/myPurchases' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Overview
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/newPurchase"
                                    className={pathName == '/newPurchase' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    All Purchase
                                </Link>
                            </li>
                        </ul>
                    </li> */}
          {/* <li className={activeData == '/inventory' ? "sidebarItems dropdown active" : "sidebarItems dropdown"}>
                        <Link to="#"
                            className={activeData === "/inventory" ? "sidebarLinks dropdown-toggle show" : "sidebarLinks dropdown-toggle"}
                            onClick={() => activeData === "/inventory" ? setActiveData("") : setActiveData("/inventory")}
                            id="sidebarDropdown">
                            <img src={inventory} className="img-fluid" alt="" />
                            <span className='menusTxt'>Inventory</span>
                            <i class="fa-solid fa-angle-down caretIcon"></i>
                        </Link>
                        <ul className={activeData === '/inventory' ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                            aria-labelledby="sidebarDropdown">
                            <li className='sidebarInnerItems'>
                                <Link to="/inventory"
                                    className={pathName == '/inventory' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Overview
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/inventoryList"
                                    className={pathName == '/inventoryList' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    List of inventories
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/lowstockInventory"
                                    className={pathName == '/lowstockInventory' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Low-Stock inventories
                                </Link>
                            </li>
                        </ul>
                    </li> */}
          <li
            className={
              activeData == "/tracking"
                ? "sidebarItems dropdown active"
                : "sidebarItems dropdown"
            }
          >
            <Link
              to="#"
              className={
                activeData === "/tracking"
                  ? "sidebarLinks dropdown-toggle show"
                  : "sidebarLinks dropdown-toggle"
              }
              onClick={() =>
                activeData === "/tracking"
                  ? setActiveData("")
                  : setActiveData("/tracking")
              }
              id="sidebarDropdown"
            >
              <img src={trackings} className="img-fluid" alt="" />
              <span className="menusTxt">Trackings</span>
              <i class="fa-solid fa-angle-down caretIcon"></i>
            </Link>
            <ul
              className={
                activeData === "/tracking"
                  ? "dropdown-menu sidebarDropMenus show"
                  : "dropdown-menu sidebarDropMenus"
              }
              aria-labelledby="sidebarDropdown"
            >
              <li className="sidebarInnerItems">
                <Link
                  to="/tracking"
                  className={
                    pathName == "/tracking"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Order Tracking
                </Link>
              </li>
              {/* <li className='sidebarInnerItems'>
                                <Link to="/purchaseTracking"
                                    className={pathName == '/purchaseTracking' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Purchase Tracking
                                </Link>
                            </li> */}
            </ul>
          </li>

          <li
            className={
              activeData == "/WalletMain" ||
              activeData == "/transections" ||
              activeData == "/jbrwallet" ||
              activeData == "/localcurrencywallet" ||
              activeData == "/withdrawal" ||
              activeData == "/walletPayment" ||
              activeData == "/walletRefund"
                ? "sidebarItems dropdown active"
                : "sidebarItems dropdown"
            }
          >
            <Link
              to="#"
              className={
                activeData === "/WalletMain" ||
                activeData == "/transections" ||
                activeData == "/jbrwallet" ||
                activeData == "/localcurrencywallet" ||
                activeData == "/withdrawal" ||
                activeData == "/walletPayment" ||
                activeData == "/walletRefund"
                  ? "sidebarLinks dropdown-toggle show"
                  : "sidebarLinks dropdown-toggle"
              }
              onClick={() =>
                activeData === "/WalletMain" ||
                activeData == "/transections" ||
                activeData == "/jbrwallet" ||
                activeData == "/localcurrencywallet" ||
                activeData == "/withdrawal" ||
                activeData == "/walletPayment" ||
                activeData == "/walletRefund"
                  ? setActiveData("")
                  : setActiveData("/WalletMain")
              }
              id="sidebarDropdown"
            >
              <img src={wallet} className="img-fluid" alt="" />
              <span className="menusTxt">Wallet</span>
              <i class="fa-solid fa-angle-down caretIcon"></i>
            </Link>
            <ul
              className={
                activeData === "/WalletMain" ||
                activeData == "/transections" ||
                activeData == "/jbrwallet" ||
                activeData == "/localcurrencywallet" ||
                activeData == "/withdrawal" ||
                activeData == "/walletPayment" ||
                activeData == "/walletRefund"
                  ? "dropdown-menu sidebarDropMenus show"
                  : "dropdown-menu sidebarDropMenus"
              }
              aria-labelledby="sidebarDropdown"
            >
              <li className="sidebarInnerItems">
                <Link
                  to="/WalletMain"
                  className={
                    pathName == "/WalletMain"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Overview
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/transections"
                  className={
                    pathName == "/transections"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Transactions
                </Link>
              </li>
              {/* <li className="sidebarInnerItems">
                <Link
                  to="/withdrawal"
                  className={
                    pathName == "/withdrawal" ||
                    pathName == "/instantbankTransfer"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Withdrawals
                </Link>
              </li> */}
            </ul>
          </li>

          <li
            className={
              activeData == "/userList"
                ? "sidebarItems dropdown active"
                : "sidebarItems dropdown"
            }
          >
            <Link
              to="#"
              className={
                activeData === "/userList"
                  ? "sidebarLinks dropdown-toggle show"
                  : "sidebarLinks dropdown-toggle"
              }
              onClick={() =>
                activeData === "/userList"
                  ? setActiveData("")
                  : setActiveData("/userList")
              }
              id="sidebarDropdown"
            >
              <img src={users} className="img-fluid" alt="" />
              <span className="menusTxt">User List</span>
              <i class="fa-solid fa-angle-down caretIcon"></i>
            </Link>
            <ul
              className={
                activeData === "/userList"
                  ? "dropdown-menu sidebarDropMenus show"
                  : "dropdown-menu sidebarDropMenus"
              }
              aria-labelledby="sidebarDropdown"
            >
              <li className="sidebarInnerItems">
                <Link
                  to="/userList"
                  className={
                    pathName == "/userList" ||
                    pathName === "/customer" ||
                    pathName === "/consumerProfile" ||
                    pathName === "/consumerOrder"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Customers
                </Link>
              </li>
              {ADMIN()?.length > 0 && (
                <>
                  <li className="sidebarInnerItems">
                    <Link
                      to="/user-list/manufacturer"
                      className={
                        pathName.split("/")[2] == "manufacturer" ||
                        pathName.split("/")[2] === "manufacturer"
                          ? "dropdown-item sidebarInnerLink active"
                          : "dropdown-item sidebarInnerLink"
                      }
                    >
                      <svg
                        width="6"
                        height="6"
                        viewBox="0 0 6 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="3"
                          cy="3"
                          r="3"
                          fill="#DFDFDF"
                          className="circledot"
                        />
                      </svg>
                      Manufacturers
                    </Link>
                  </li>
                  <li className="sidebarInnerItems">
                    <Link
                      to="/user-list/whole_seller"
                      className={
                        pathName.split("/")[2] == "whole_seller" ||
                        pathName.split("/")[2] === "whole_seller"
                          ? "dropdown-item sidebarInnerLink active"
                          : "dropdown-item sidebarInnerLink"
                      }
                    >
                      <svg
                        width="6"
                        height="6"
                        viewBox="0 0 6 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="3"
                          cy="3"
                          r="3"
                          fill="#DFDFDF"
                          className="circledot"
                        />
                      </svg>
                      Wholesalers
                    </Link>
                  </li>
                  <li className="sidebarInnerItems">
                    <Link
                      to="/user-list/retailer"
                      className={
                        pathName.split("/")[2] == "retailer" ||
                        pathName.split("/")[2] === "retailer"
                          ? "dropdown-item sidebarInnerLink active"
                          : "dropdown-item sidebarInnerLink"
                      }
                    >
                      <svg
                        width="6"
                        height="6"
                        viewBox="0 0 6 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="3"
                          cy="3"
                          r="3"
                          fill="#DFDFDF"
                          className="circledot"
                        />
                      </svg>
                      Retailers
                    </Link>
                  </li>
                </>
              )}
              {/* <li className='sidebarInnerItems'>
                                <Link to="/consumer"
                                    className={pathName == '/consumer' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Consumers
                                </Link>
                            </li> */}
            </ul>
          </li>
          {/* <li className={activeData == '/campaign' || activeData === "/promotional-activites" || activeData === "/connected-account" || activeData === "/template" ? "sidebarItems dropdown active" : "sidebarItems dropdown"}>
                        <Link to="#"
                            className={activeData === "/campaign" || activeData === "/promotional-activites" || activeData === "/connected-account" || activeData === "/template" ? "sidebarLinks dropdown-toggle show" : "sidebarLinks dropdown-toggle"}
                            onClick={() => activeData === "/campaign" || activeData === "/promotional-activites" || activeData === "/connected-account" || activeData === "/template" ? setActiveData("") : setActiveData("/campaign")}
                            id="sidebarDropdown">
                            <img src={campaign} className="img-fluid" alt="" />
                            <span className='menusTxt'>Campaign</span>
                            <i class="fa-solid fa-angle-down caretIcon"></i>
                        </Link>
                        <ul className={activeData === '/campaign' || activeData === "/promotional-activites" || activeData === "/connected-account" || activeData === "/template" ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                            aria-labelledby="sidebarDropdown">
                            <li className='sidebarInnerItems'>
                                <Link to="/campaign"
                                    className={pathName == '/campaign' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Overview
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/promotional-activites"
                                    className={pathName == '/promotional-activites' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Promotional Activities
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/template"
                                    className={pathName == '/template' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Templates
                                </Link>
                            </li>
                        </ul>
                    </li> */}
          <li
            className={
              activeData == "/analytics " || activeData === "/stats"
                ? "sidebarItems dropdown active"
                : "sidebarItems dropdown"
            }
          >
            <Link
              to="#"
              className={
                activeData === "/analytics" || activeData === "/stats"
                  ? "sidebarLinks dropdown-toggle show"
                  : "sidebarLinks dropdown-toggle"
              }
              onClick={() =>
                activeData === "/analytics" || activeData === "/stats"
                  ? setActiveData("")
                  : setActiveData("/analytics")
              }
              id="sidebarDropdown"
            >
              <img src={analyticsicon} className="img-fluid" alt="" />
              <span className="menusTxt">Analytics </span>
              <i class="fa-solid fa-angle-down caretIcon"></i>
            </Link>
            <ul
              className={
                activeData === "/analytics" || activeData === "/stats"
                  ? "dropdown-menu sidebarDropMenus show"
                  : "dropdown-menu sidebarDropMenus"
              }
              aria-labelledby="sidebarDropdown"
            >
              <li className="sidebarInnerItems">
                <Link
                  to="/analytics"
                  className={
                    pathName == "/analytics"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Overview
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/Gross-Profits"
                  className={
                    pathName == "/Gross-Profits"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Gross Profits
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/Total-Revenue"
                  className={
                    pathName == "/Total-Revenue"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Total Revenue
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/Total-Costs"
                  className={
                    pathName == "/Total-Costs"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Total Costs
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/Total-POS-Orders"
                  className={
                    pathName == "/Total-POS-Orders"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Total POS Orders
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/Total-Delivery-Orders"
                  className={
                    pathName == "/Total-Delivery-Orders"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Total Delivery Orders
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/Total-Shipping-Orders"
                  className={
                    pathName == "/Total-Shipping-Orders"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Total Shipping Orders
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/Total-Orders"
                  className={
                    pathName == "/Total-Orders"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Total Orders
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/Total-Inventory"
                  className={
                    pathName == "/Total-Inventory"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Total Inventory
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/Total-Product-Sold"
                  className={
                    pathName == "/Total-Product-Sold"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Total Product Sold
                </Link>
              </li>
            </ul>
          </li>
          <li
            className={
              activeData == "/catalogs"
                ? "sidebarItems dropdown active"
                : "sidebarItems dropdown"
            }
          >
            <Link
              to="/catalogs"
              className={
                activeData === "/catalogs"
                  ? "sidebarLinks dropdown-toggle show"
                  : "sidebarLinks dropdown-toggle"
              }
              onClick={() =>
                activeData === "/catalogs"
                  ? setActiveData("")
                  : setActiveData("/catalogs")
              }
              id="sidebarDropdown"
            >
              <img src={catalog} className="img-fluid" alt="" />
              <span className="menusTxt">Catalogs </span>
            </Link>
          </li>
          {/* <li
            className={
              activeData == "/giftcard"
                ? "sidebarItems dropdown active"
                : "sidebarItems dropdown"
            }
          >
            <Link
              to="/giftcard"
              className={
                activeData === "/giftcard"
                  ? "sidebarLinks dropdown-toggle show"
                  : "sidebarLinks dropdown-toggle"
              }
              onClick={() =>
                activeData === "/giftcard"
                  ? setActiveData("")
                  : setActiveData("/giftcard")
              }
              id="sidebarDropdown"
            >
              <img src={analytics} className="img-fluid" alt="" />
              <span className="menusTxt">Gift Card </span>
            </Link>
          </li> */}

          <li
            className={
              activeData == "/systemEmployee" ||
              activeData === "/staffRole" ||
              activeData === "/legal" ||
              activeData === "/taxes" ||
              activeData === "/shippingPickup" ||
              activeData === "/planSubscription" ||
              activeData === "/walletConfig" ||
              activeData === "/notification" ||
              activeData === "/invoice" ||
              activeData === "/pos" ||
              activeData === "/languages" ||
              activeData === "/security" ||
              activeData === "/faq"
                ? "sidebarItems dropdown active"
                : "sidebarItems dropdown"
            }
          >
            <Link
              to="#"
              className={
                activeData === "/systemEmployee" ||
                activeData === "/staffRole" ||
                activeData === "/legal" ||
                activeData === "/staffRole" ||
                activeData === "/legal" ||
                activeData === "/taxes" ||
                activeData === "/shippingPickup" ||
                activeData === "/planSubscription" ||
                activeData === "/walletConfig" ||
                activeData === "/notification" ||
                activeData === "/invoice" ||
                activeData === "/pos" ||
                activeData === "/languages" ||
                activeData === "/security" ||
                activeData === "/faq"
                  ? "sidebarLinks dropdown-toggle show"
                  : "sidebarLinks dropdown-toggle"
              }
              onClick={() =>
                activeData === "/systemEmployee" ||
                activeData === "/staffRole" ||
                activeData === "/staffRole" ||
                activeData === "/legal" ||
                activeData === "/taxes" ||
                activeData === "/shippingPickup" ||
                activeData === "/planSubscription" ||
                activeData === "/walletConfig" ||
                activeData === "/notification" ||
                activeData === "/invoice" ||
                activeData === "/pos" ||
                activeData === "/languages" ||
                activeData === "/security" ||
                activeData === "/faq"
                  ? setActiveData("")
                  : setActiveData("/systemEmployee")
              }
              id="sidebarDropdown"
            >
              <img src={setting} className="img-fluid" alt="" />
              <span className="menusTxt">System Config</span>
              <i class="fa-solid fa-angle-down caretIcon"></i>
            </Link>
            <ul
              className={
                activeData === "/systemEmployee" ||
                activeData === "/staffRole" ||
                activeData === "/legal" ||
                activeData === "/taxes" ||
                activeData === "/shippingPickup" ||
                activeData === "/planSubscription" ||
                activeData === "/walletConfig" ||
                activeData === "/notification" ||
                activeData === "/invoice" ||
                activeData === "/pos" ||
                activeData === "/languages" ||
                activeData === "/security" ||
                activeData === "/faq"
                  ? "dropdown-menu sidebarDropMenus show"
                  : "dropdown-menu sidebarDropMenus"
              }
              aria-labelledby="sidebarDropdown"
            >
              {/* <li className='sidebarInnerItems'>
                                <Link to="/systemEmployee"
                                    className={pathName == '/systemEmployee' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Employee
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/staffRole"
                                    className={pathName == '/staffRole' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Staff Roles
                                </Link>
                            </li> */}
              <li className="sidebarInnerItems">
                <Link
                  to="/businesseditDetails"
                  className={
                    pathName == "/businesseditDetails"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Business Details
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/personalDetail"
                  className={
                    pathName == "/personalDetail"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Personal Details
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/locations"
                  className={
                    pathName == "/locations"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Business Locations
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/retailOrder"
                  className={
                    pathName == "/retailOrder"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Retail Orders
                </Link>
              </li>
              {/* <li className='sidebarInnerItems'>
                                <Link to="/systemConfigPlan"
                                    className={pathName == '/systemConfigPlan' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Plans
                                </Link>
                            </li> */}
              <li className="sidebarInnerItems">
                <Link
                  to="/planSubscription"
                  className={
                    pathName == "/planSubscription"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Plans & Subscription
                </Link>
              </li>
              {/* <li className="sidebarInnerItems">
                <Link
                  to={
                    merchantStatus?.payload?.wallet_status === true
                      ? "/bank-details"
                      : "#"
                  }
                  className={
                    pathName == "/bank-details"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Bank Account
                </Link>
              </li> */}
              <li className="sidebarInnerItems">
                <Link
                  to="/payout-details"
                  className={
                    pathName == "/payout-details"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Payout Details
                </Link>
              </li>
              {/* <li className="sidebarInnerItems">
                <Link
                  to="/walletConfig"
                  className={
                    pathName == "/walletConfig"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Wallet Config
                </Link>
              </li> */}

              {/* <li className='sidebarInnerItems'>
                                <Link to="/device"
                                    className={pathName == '/device' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Device
                                </Link>
                            </li> */}

              {/* <li className='sidebarInnerItems'>
                                <Link to="/shippingPickup"
                                    className={pathName == '/shippingPickup' || pathName.split('/')[1] === "pickupAddress" || pathName.split('/')[1] === "local-pickup"
                                        || pathName.split('/')[1] === "dropOff-location" || pathName.split('/')[1] === "shipping-location"
                                        ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Shipping & Pickup
                                </Link>
                            </li> */}
              <li className="sidebarInnerItems">
                <Link
                  to="/taxes"
                  className={
                    pathName == "/taxes"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Taxes
                </Link>
              </li>
              {/* <li className='sidebarInnerItems'>
                                <Link to="/systemTaxes"
                                    className={pathName == '/systemTaxes' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    System Taxes Configation
                                 </Link>
                            </li> */}
              {/* <li className='sidebarInnerItems'>
                                <Link to="/legal"
                                    className={pathName == '/legal' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Legal
                                </Link>
                            </li> */}
              <li className="sidebarInnerItems">
                <Link
                  to="/invoice"
                  className={
                    pathName == "/invoice"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Invoice
                </Link>
              </li>
              {/* <li className='sidebarInnerItems'>
                                <Link to="/pos"
                                    className={pathName == '/pos' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    POS
                                </Link>
                            </li> */}
              {/* <li className='sidebarInnerItems'>
                                <Link to="/notification"
                                    className={pathName == '/notification' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Notifications
                                </Link>
                            </li> */}
              <li className="sidebarInnerItems">
                <Link
                  to="/languages"
                  className={
                    pathName == "/languages"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Language
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/security"
                  className={
                    pathName == "/security"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Security
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/faq"
                  className={
                    pathName == "/faq"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  FAQ
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/policies"
                  className={
                    pathName == "/policies"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Policies
                </Link>
              </li>
              <li className="sidebarInnerItems">
                <Link
                  to="/quickbook"
                  className={
                    pathName == "/quickbook"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Quick Book
                </Link>
              </li>
              {sellerType == "manufacturer" || sellerType == "whole_seller" ? (
                ""
              ) : (
                <li className="sidebarInnerItems">
                  <Link
                    to="/systemService"
                    className={
                      pathName == "/systemService"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                  >
                    <svg
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="3"
                        cy="3"
                        r="3"
                        fill="#DFDFDF"
                        className="circledot"
                      />
                    </svg>
                    Service
                  </Link>
                </li>
              )}
              <li className="sidebarInnerItems">
                <Link
                  to="/pluginIntegrations"
                  className={
                    pathName == "/pluginIntegrations"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Plugins & Integrations
                </Link>
              </li>
              {ADMIN()?.length > 0 && (
                <li className="sidebarInnerItems">
                  <Link
                    to="/app-settings"
                    className={
                      pathName == "/app-settings"
                        ? "dropdown-item sidebarInnerLink active"
                        : "dropdown-item sidebarInnerLink"
                    }
                  >
                    <svg
                      width="6"
                      height="6"
                      viewBox="0 0 6 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="3"
                        cy="3"
                        r="3"
                        fill="#DFDFDF"
                        className="circledot"
                      />
                    </svg>
                    App Settings
                  </Link>
                </li>
              )}
            </ul>
          </li>
          <li
            className={
              activeData == "/support" ||
              activeData === "/supportTicket" ||
              activeData === "/supportCall" ||
              activeData === "/supportChat" ||
              activeData === "/supportEmail"
                ? "sidebarItems dropdown active"
                : "sidebarItems dropdown"
            }
          >
            <Link
              to="#"
              className={
                activeData === "/support" ||
                activeData === "/supportTicket" ||
                activeData === "/supportCall" ||
                activeData === "/supportChat" ||
                activeData === "/supportEmail"
                  ? "sidebarLinks dropdown-toggle show"
                  : "sidebarLinks dropdown-toggle"
              }
              onClick={() =>
                activeData === "/support" ||
                activeData === "/supportTicket" ||
                activeData === "/supportCall" ||
                activeData === "/supportChat" ||
                activeData === "/supportEmail"
                  ? setActiveData("")
                  : setActiveData("/support")
              }
              id="sidebarDropdown"
            >
              <img src={support} className="img-fluid" alt="" />
              <span className="menusTxt">Support</span>
              <i class="fa-solid fa-angle-down caretIcon"></i>
            </Link>
            <ul
              className={
                activeData === "/support" ||
                activeData === "/supportTicket" ||
                activeData === "/supportCall" ||
                activeData === "/supportChat" ||
                activeData === "/supportEmail"
                  ? "dropdown-menu sidebarDropMenus show"
                  : "dropdown-menu sidebarDropMenus"
              }
              aria-labelledby="sidebarDropdown"
            >
              <li className="sidebarInnerItems">
                <Link
                  to="/support"
                  className={
                    pathName == "/support"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  Support
                </Link>
              </li>
              {/* <li className='sidebarInnerItems'>
                                <Link to="/agents"
                                    className={pathName == '/agents' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Agents
                                </Link>
                            </li> */}
              <li className="sidebarInnerItems">
                <Link
                  to="/supportTicket"
                  className={
                    pathName == "/supportTicket" ||
                    pathName?.split("/")[1] === "supportticketDetail"
                      ? "dropdown-item sidebarInnerLink active"
                      : "dropdown-item sidebarInnerLink"
                  }
                >
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill="#DFDFDF"
                      className="circledot"
                    />
                  </svg>
                  My Tickets
                </Link>
              </li>

              {/* <li className='sidebarInnerItems'>
                                <Link to="/supportCall"
                                    className={pathName == '/supportCall' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Calls
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/supportChat"
                                    className={pathName == '/supportChat' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Chats
                                </Link>
                            </li>
                            <li className='sidebarInnerItems'>
                                <Link to="/supportEmail"
                                    className={pathName == '/supportEmail' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Emails
                                </Link>
                            </li> */}
            </ul>
          </li>

          {/* new */}

          <li
            className={
              activeData == "/Coupon"
                ? "sidebarItems dropdown active"
                : "sidebarItems dropdown"
            }
          >
            <Link
              to="/Coupon"
              onClick={() =>
                activeData === "/Coupon"
                  ? setActiveData("")
                  : setActiveData("/Coupon")
              }
              className={
                activeData === "/Coupon"
                  ? "sidebarLinks dropdown-toggle show"
                  : "sidebarLinks dropdown-toggle"
              }
              id="sidebarDropdown"
            >
              <i class="fa-regular fa-rectangle-list"></i>

              <span className="menusTxt coupons">Coupons</span>
              {/* <i className="fa-solid fa-caret-down caretIcon"
                            ></i> */}
            </Link>
            {/* Start SidebarInnerMenus */}
            {/* <ul className={activeData === "/wallet" ? "dropdown-menu sidebarDropMenus show" : "dropdown-menu sidebarDropMenus"}
                            aria-labelledby="sidebarDropdown">
                            <li className='sidebarInnerItems'>
                                <Link
                                    to="/wallet"
                                    className={pathName == '/wallet' ? "dropdown-item sidebarInnerLink active" : "dropdown-item sidebarInnerLink"}>
                                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="3" cy="3" r="3" fill="#DFDFDF" className='circledot' />
                                    </svg>
                                    Wallet
                                </Link>
                            </li>
                        </ul> */}
            {/* End SidebarInnerMenus */}
          </li>
        </div>
        <div className="sidbarfixedMenus">
          <li
            className="sidebarItems"
            onClick={() => {
              userLogout();
            }}
          >
            <Link
              to="#"
              className="sidebarLinks"
              onClick={() => setActiveData("power")}
            >
              <img src={logoutImg} className="img-fluid" alt="" />
              <span className="menusTxt">Logout</span>
            </Link>
          </li>
          <li></li>
        </div>
      </ul>
      {/* <ul className='sidebarMenus navbar_overlay_content'>
            </ul> */}
      {/* <div  className="main-sidebar" id="myNav" >
                <div className="vertical_top" id="vertical_top">
                    <a className="closebtn2"
                        onClick={() => {
                            closeSide();
                            closeActions()
                        }}
                    ><i className="las la-times"></i></a>
                    <span className="expanded_tab"><i className="fas fa-arrows-alt-h"></i></span>
                </div>
            </div> */}
    </aside>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  nav: state.nav,
});
const mapDispatchToProps = (dispatch) => ({
  // logout: () =>  dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
